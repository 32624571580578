import filter from 'lodash/filter';
import flowRight from 'lodash/flowRight';
import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

//helpers
import {normalizeElasticSearchResult} from 'shared/utils/search-result-utils';

//components
import {FormattedMessage} from 'shared/components/FormattedComponents';
import BaseSearchResultList from './BaseSearchResultList';

//styled-components
import {Tag} from './styles';

//messages
import messages from './messages';
import {useSearchQuery} from 'shared/hooks/useSearchQuery';

const ElasticSearchResultList = flowRight(
  inject('store'),
  observer
)(({searchTerm, selectedTags, skipSearch, store, size}) => {
  const {explorerPage} = store;
  const {removeSelectedTag, allSelectedTags} = explorerPage;

  const {lastQueryResults} = useSearchQuery({searchTerm, store, skipSearch, size, tags: allSelectedTags});

  const results = filter(lastQueryResults, result => !result._index.includes('tags'));
  const normalizedResults = results.map(normalizeElasticSearchResult);

  return (
    <BaseSearchResultList
      genericResults={normalizedResults}
      introSectionContent={selectedTags.map(tagName => (
        <Tag
          key={tagName}
          iconName="close"
          onIconClick={() => removeSelectedTag(tagName)}
          iconSize={8}
          tagName={tagName}
        />
      ))}
      noResultsSuggestionContent={
        selectedTags.length ? (
          <FormattedMessage {...messages.pageTitle_removeFilter} />
        ) : (
          <FormattedMessage {...messages.pageTitle_searchAnotherTerm} />
        )
      }
      searchTerm={searchTerm}
    />
  );
});

ElasticSearchResultList.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
};

export default ElasticSearchResultList;
