import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

//helpers
import {SHOULD_SHOW_INSTRUCTIONS_ON_SIMPLE_SEARCH} from 'shared/constants';
import {NormalizedSearchResultPropType} from 'shared/utils/search-result-utils';

//components
import {FormattedMessage} from 'shared/components/FormattedComponents';

//messages
import messages from './messages';

//styles
import {
  Content,
  DropdownTitle,
  DropdownTitleInner,
  GenericWrapper,
  MoreLink,
  NoResults,
  SearchBarDropdown,
  Spinner,
  StyledResultRow,
  SubSection,
  SubSectionWrapper,
  WarningIcon
} from './styles';

@inject('store')
@observer
class BaseSearchBarDropdownComponent extends Component {
  static defaultProps = {
    maxResults: Infinity
  };

  static propTypes = {
    genericResults: PropTypes.arrayOf(NormalizedSearchResultPropType),
    guideResults: PropTypes.arrayOf(NormalizedSearchResultPropType),
    hasError: PropTypes.bool,
    instructionResults: PropTypes.arrayOf(NormalizedSearchResultPropType),
    introSectionContent: PropTypes.node,
    introSectionTitle: PropTypes.node,
    isLoading: PropTypes.bool,
    isSimpleSearchMode: PropTypes.bool,
    onModeToggleClick: PropTypes.func,
    maxResults: PropTypes.number
  };

  renderResultsSection = (results, titleMessage) => {
    const {hasError, maxResults, store} = this.props;
    const {explorerPage} = store;
    const {goToSearchPage} = explorerPage;

    if (hasError || !results) {
      return null;
    }

    return (
      <SubSectionWrapper>
        <SubSection>
          <FormattedMessage {...titleMessage} />
        </SubSection>
        {results.length ? (
          results.slice(0, maxResults).map(result => <StyledResultRow key={result.id} result={result} />)
        ) : (
          <NoResults>
            <FormattedMessage {...messages.searchPage_noResults} />
          </NoResults>
        )}
        {results.length > maxResults && (
          <GenericWrapper align="right">
            <MoreLink onClick={goToSearchPage}>
              <FormattedMessage {...messages.searchPage_viewAllResults} />
            </MoreLink>
          </GenericWrapper>
        )}
      </SubSectionWrapper>
    );
  };

  render() {
    const {
      isLoading,
      genericResults,
      guideResults,
      hasError,
      instructionResults,
      introSectionContent,
      introSectionTitle,
      isSimpleSearchMode
    } = this.props;

    return (
      <SearchBarDropdown>
        <DropdownTitle>
          <DropdownTitleInner>
            {isSimpleSearchMode ? (
              <FormattedMessage {...messages.pageTitle_searchResults} />
            ) : (
              <FormattedMessage {...messages.searchPage_dropDownTitle} />
            )}
          </DropdownTitleInner>
          {/* {CAN_USE_ADVANCED_SEARCH && (
            <ModeToggle onClick={onModeToggleClick}>
              {isSimpleSearchMode ? (
                <FormattedMessage {...messages.searchPage_advanced} />
              ) : (
                <FormattedMessage {...messages.searchPage_simple} />
              )}
            </ModeToggle>
          )} */}
        </DropdownTitle>
        <Content isTransparent={isLoading}>
          {introSectionContent && (
            <SubSectionWrapper>
              <SubSection>{introSectionTitle}</SubSection>
              {introSectionContent}
            </SubSectionWrapper>
          )}
          {hasError && (
            <SubSectionWrapper>
              <SubSection>
                <FormattedMessage {...messages.error} />
              </SubSection>
              <GenericWrapper>
                <WarningIcon />
                <FormattedMessage {...messages.errorFetchingResults} />
              </GenericWrapper>
            </SubSectionWrapper>
          )}
          {this.renderResultsSection(genericResults, messages.searchPage_searchResults)}
          {this.renderResultsSection(guideResults, messages.searchPage_guides)}
          {(!isSimpleSearchMode || SHOULD_SHOW_INSTRUCTIONS_ON_SIMPLE_SEARCH) &&
            this.renderResultsSection(instructionResults, messages.searchPage_instructions)}
        </Content>
        {isLoading && <Spinner />}
      </SearchBarDropdown>
    );
  }
}

export default BaseSearchBarDropdownComponent;
