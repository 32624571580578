import flowRight from 'lodash/flowRight';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl} from 'react-intl';

//helpers
import {normalizeElasticSearchResult} from 'shared/utils/search-result-utils';

//queries
import {useSearchQuery} from 'shared/hooks/useSearchQuery';

//components
import {FormattedMessage} from 'shared/components/FormattedComponents';
import BaseSearchBarDropdown from './BaseSearchBarDropdown';

//messages
import messages from './messages';

//styles
import {TagWrapper, Tag} from './styles';

//lodash
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

/**
 * Fetches Elastic Search results from the API and feeds them to the
 * <BaseSearchBarDropdown /> component.
 */
const ElasticSearchBarDropdownComponent = flowRight(
  inject('store'),
  observer
)(({onModeToggleClick, searchTerm, skipSearch, store, intl}) => {
  const {explorerPage} = store;
  const {addSelectedTag, selectedTags, allSelectedTags, setTranslations} = explorerPage;
  const {formatMessage} = intl;

  const {lastQueryResults, loading, error} = useSearchQuery({searchTerm, store, skipSearch, tags: allSelectedTags});

  const [tagsResults, results] = partition(lastQueryResults, result => result._index.includes('tags'));

  const normalizedResults = results.map(normalizeElasticSearchResult);
  const availableTags = tagsResults
    .map(tagResult => tagResult._source.title)
    .filter(tagTitle => !selectedTags.includes(tagTitle))
    .slice(0, 5);

  const hasError = !!error;

  const translations = mapValues(pick(messages, ['loading']), message => formatMessage(message));

  setTranslations(translations);

  return (
    <BaseSearchBarDropdown
      genericResults={normalizedResults}
      hasError={hasError}
      introSectionContent={
        !isEmpty(availableTags) && (
          <TagWrapper>
            {availableTags.map((tagName, index) => (
              <Tag
                key={`${tagName}-${index}`}
                iconName="plus"
                onClick={() => addSelectedTag(tagName)}
                tagName={tagName}
              />
            ))}
          </TagWrapper>
        )
      }
      introSectionTitle={<FormattedMessage {...messages.searchPage_tags} />}
      isLoading={loading}
      onModeToggleClick={onModeToggleClick}
    />
  );
});

ElasticSearchBarDropdownComponent.propTypes = {
  searchTerm: PropTypes.string,
  skipSearch: PropTypes.bool
};

export default injectIntl(ElasticSearchBarDropdownComponent);
