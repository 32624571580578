import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {bindField} from 'shared/utils/input-utils';
import enhanceWithClickOutside from 'react-click-outside';

//styled-components
import {SearchBar, SearchBarWrapper, SearchResetButton, StyledInput, Tag} from './styles';

// components
import Icon from 'shared/components/Icon';
import SearchBarDropdown from './SearchBarDropdown';

import messages from './messages';
@enhanceWithClickOutside
@inject('store')
@observer
class SearchBarComponent extends Component {
  handleClickOutside() {
    const {store} = this.props;
    const {explorerPage} = store;
    const {isTagPickerDialogOpen, toggleSearchInputVisibility} = explorerPage;

    if (!isTagPickerDialogOpen) {
      toggleSearchInputVisibility(false);
    }
  }

  handleInputKeydown = event => {
    const {store} = this.props;
    const {explorerPage} = store;
    const {
      clearSearch,
      goToSearchPage,
      searchTerm,
      addTagFromSearchTerm,
      removeSelectedTag,
      allSelectedTags,
      searchOnlyTags
    } = explorerPage;

    if (event.keyCode === 27) {
      clearSearch('byKey');
    }

    if (event.keyCode === 13) {
      searchOnlyTags ? addTagFromSearchTerm() : goToSearchPage();
    }

    if (event.keyCode === 8 && !searchTerm && allSelectedTags.length) {
      removeSelectedTag(allSelectedTags[allSelectedTags.length - 1]);
    }
  };

  handleModeToggleClick = () => {
    const {store} = this.props;
    const {explorerPage} = store;

    if (this.inputElement) {
      this.inputElement.focus();
    }

    explorerPage.toggleSimpleSearchDropdownMode();
  };

  handleResetClick = () => {
    const {store} = this.props;
    const {explorerPage} = store;

    if (this.inputElement) {
      this.inputElement.focus();
    }

    explorerPage.clearSearch('byIconBtn');
  };

  setInputRef = ref => {
    this.inputElement = ref;
  };

  componentWillReceiveProps = () => {
    if (this.inputElement) {
      this.inputElement.focus();
    }
  };

  render() {
    const {intl, store, menuIcon} = this.props;
    const {formatMessage} = intl;
    const {explorerPage} = store;
    const {
      filterContentForm,
      onSearchTermChange,
      searchTerm,
      debouncedSearchTerm,
      shouldUseSimpleSearchDropdownMode,
      shouldSearchInputBeVisible,
      allSelectedTags,
      removeSelectedTag
    } = explorerPage;

    const inputPlaceholder = shouldUseSimpleSearchDropdownMode
      ? formatMessage(messages.formFieldPlaceholder_searchGuidesOrInstructions)
      : formatMessage(messages.formFieldPlaceholder_explorerSearch);

    return (
      <SearchBarWrapper searchBarVisible={shouldSearchInputBeVisible}>
        {menuIcon}
        {shouldSearchInputBeVisible && (
          <SearchBar>
            {!shouldUseSimpleSearchDropdownMode &&
              allSelectedTags.map((tag, i) => (
                <Tag key={i} tagName={tag} iconName={'close'} iconSize={8} onClick={() => removeSelectedTag(tag)} />
              ))}
            <StyledInput
              {...bindField(filterContentForm, 'searchTerm', {
                onChangeReaction: event => {
                  onSearchTermChange(event.target.value);
                }
              })}
              autoComplete="off"
              autoFocus={true}
              ref={this.setInputRef}
              onKeyDown={this.handleInputKeydown}
              placeholder={inputPlaceholder}
              type="search"
              value={searchTerm || ''}
            />
            {searchTerm && (
              <SearchResetButton onClick={this.handleResetClick}>
                <Icon name={'close'} size={12} />
              </SearchResetButton>
            )}
          </SearchBar>
        )}
        {shouldSearchInputBeVisible && (
          <SearchBarDropdown onModeToggleClick={this.handleModeToggleClick} searchTerm={debouncedSearchTerm} />
        )}
      </SearchBarWrapper>
    );
  }
}

export default injectIntl(SearchBarComponent);
